.container-map {
    position: absolute;
    top: 0;
    bottom: 0;
    width: 100%;
  }
  .marker {
    cursor: pointer;
    padding: 4px 10px;
    box-shadow: 0px 4px 4px rgb(0 0 0 / 25%);
    border-radius: 28px;
    background: #FFFFFF;
    font-weight: 400;
    font-size: 15px;
    font-weight: 500;
    line-height: 17px;
  }
  .marker-location{
    background-image: url(../assest/icon/current-location.png);
    background-repeat: no-repeat;
    width: 48px;
    height: 48px;
    background-size: contain;
  }
  .mapboxgl-ctrl-logo {
    display: none !important;
  }
  .mapboxgl-ctrl-attrib {
    display: none !important;
  }
  .icon-tiny {
    width: 14px;
    height: 14px;
    margin: 0 5px 2px 0;
  }
  .marker-highlighted {
    background: #0F1F68 !important;
    color: white;
  }
  .icon-highlighted {
    filter: invert(100%) sepia(94%) saturate(24%) hue-rotate(53deg) brightness(106%) contrast(106%);
  }