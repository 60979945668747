
.body {
min-height: 100vh;

}

.home{
    background-color:#f5f5f5;
    background-repeat: no-repeat;
    background-size: cover;
}
.head{
    display: flex;
    justify-content: space-between;
    position: absolute;
    align-items: center;
    padding: 15px;
    z-index: 9;
    width: 100%;
  
    .center{transform:scale(0.75);
        display: flex;
        flex-direction: column;
        align-items: center;
        border-radius: 50px;
        justify-content: center;
        padding: 11px 13px;
        color: #fff;
       

        font-style: normal;
        font-weight: 500;
        font-size: 20px;
        line-height: 25px;
        background: #0F1F68;
        flex-direction: row;
        .currency{
            color: #2BC6FA;
        }
     
        .product{
            background: #2BC6FA;
            border-radius: 26px;
            font-weight: 800;
            font-size: 12px;
            line-height: 15px;
            display: flex;
            align-items: center;
            text-align: center;
            color: #000000;
            padding: 5px 9px;
            margin-left: 8px;
        }
    }

    .right-content{
        display: flex;
        flex-direction: row;
        .right{
            display: flex;
            flex-direction: row;
            .icon{
                width: 36px;
                height: 36px;
                z-index: 2;
            }
            .pill{
                font-style: normal;
                font-weight: 600;
                font-size: 16px;
                border-radius: 20px;
                margin: 3px 0 3px -20px;
                padding: 2px 10px 0 22px;
                background: #0F1F68aa;
                z-index: 1;
                color: #fff;
            }
        }
    }
}

.top{
    display: flex;align-items: center;
    .arrow{
        
        z-index: 9;
        height: 12px;
    }
    .close{
        z-index: 9;
        top: 40px;
        left: 18px;
        height: 48px;
        width: 48px;
    }
    .con{
        flex: 1;
        text-align: center;margin-left: -33px;
        line-height: 15px;transform: scale(0.75);
    }
    .title{font-weight: 600;
        font-size: 17px;
        line-height: 21px;}
    .sub-t{font-weight: 300;
        font-size: 12px;
        }
}
.map{
    text-align: center;
    .two{
        .sub{
            width: 50%;
        }
    }
    .sub{
        width: 100%;
    }
}

.location{
    right: 15px;
    display: flex;
    justify-content: flex-end;
    position: fixed;
    bottom: 95px;
}
.social{
    right: 15px;
    display: flex;
    justify-content: flex-end;
    position: fixed;
    bottom: 140px;
}
.hide-tip{
    width: 105px;
    height: 65px;
    z-index: 2;
    position: absolute;
    top: 30px;
    right: 0;
}
.hide-mask{
    opacity: 0 !important;
}
@keyframes round_animate {
    from {
      transform: rotate(0deg);
    }
  
    to {
      transform: rotate(360deg);
    }
  }
.image-circle {
    width: 40px;
    height: 40px;
    margin-top: 0px;
    margin-right: auto;
    margin-bottom: 10px;
    margin-left: auto;
    background: url("../../assest/icon/loading.svg") no-repeat;
    background-size: 100% 100%;

    animation: round_animate 5s linear infinite;
}

.chats-screen{
    position: fixed;
    left: 0;
    top: 0;
    height: 100vh;
    width: 100vw;
}
.coach{
    position: fixed;
    left: 0;
    top: 0;
    height: 100vh;
    width: 100vw;
}
.foot{
    right: 15px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #fff;
    position: fixed;
    left: 15px;
    bottom: 22.5px;
   
}
.bootom-btn-area{
    bottom: 45px;
    position: fixed;
    right: 24px;
    left: 24px;
}
.btn-block{
    display: flex;
    align-items: center;
    justify-content: space-between;
    .left{
        font-weight: 500;
        font-size: 16px;
        transform: scale(0.75);
        transform-origin: left;
    }
    .right{
        display: flex;
        font-weight: 500;
        font-size: 20px;
        line-height: 32px;
        transform: scale(0.75);
        transform-origin: right;
        .currency{
            color: #2BC6FA;
        }
       
    }
}
.adm-modal .adm-center-popup-wrap{
    width: 100%;
}
.adm-center-popup-body.list-modal{
background-color: rgba(254, 254, 254, 0.7);
}
.adm-popup-body-position-bottom {

    border-radius: 30px 30px 0px 0px;
}
.switch-area{
    background-color: #26367C;
    display: flex;    padding: 5px;
    justify-content: space-between;border-radius: 40px;    align-items: center;
    .s-btn{
        font-weight: 900;
        font-size: 13px;
        
        border-radius: 40px;
        background-color: #26367C;
        color: #fff;
        
        &.active{
            padding: 8px 15px;
            background: #FFFFFF;
            box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25), inset 0px -2px 4px rgba(15, 31, 104, 0.26);
            color: #26367C;
        }
    }
}



.show-point{
    .adm-center-popup-wrap{
        top: 80%;
    }
    .adm-modal-body{
        padding-top: 0;
        background-color: transparent;
        .adm-modal-content{
            padding: 0;
        }
        .adm-modal-footer-empty{
            height: 0;
        }
    }
}
.point{
    display: flex;
    
    .img-block{
        overflow: hidden;
        position: relative;
       
        height: 180px;
        .img{
            height: 100%;
            border-top-left-radius: 16px;
            border-bottom-left-radius: 16px;
        }
        .price{
            position: absolute;
            top: 8px;
            left: 8px;
            color: #357AEA;
            display: flex;
            .icon-small{
                width: 30px;
                height: 30px;
                z-index: 2;
            }
            .pill{
                background-color: #F2F2F2;
                border: 1px solid #CCCAC6;
                border-radius: 40px;
                font-weight: bold;
                font-size: 12px;
                line-height: 24px;
                height: 24px;
                padding: 0px 4px 0px 18px;
                margin-left: -16px;
                margin-top: 3px;
            
            }
        }
    }
    .info-block{
        background-color: #F2F2F2;
        flex: 1;
        padding: 8px;
        position: relative;    
        border-top-right-radius: 8px;
        border-bottom-right-radius: 8px;
        .title{
            font-weight: bold;
            font-size: 16px;
            line-height: 15px;
            margin-top: 8px;
        }
        .address{
            font-weight: 400;
            font-size: 13px;
            line-height: 18px;
            
            margin-bottom: 4px;
        }
        .walk{
            font-weight: 300;
            font-size: 13px;
            line-height: 14px;
            color: #4F4F4F;
            transform: scale(0.75);
            transform-origin: left;
            margin-bottom: 4px;
        }
        .views{
            display: flex;
            .icon-view{
                height: 24px;
            }
            .number{
                font-weight: 400;
                background-color: #F9F2EC;
                border-radius: 24px;
                font-size: 12px;
                line-height: 22px;
                padding: 1px 4px;
            }
        }
    }
    .btn-area{
        position: absolute;
        bottom: -24px;
        margin-left: 50%;
        left: -81px;
        .btn-img{
            height: 64px;
        }
    }
    .tag{
        font-size: 13px;
        display: inline-flex;
        padding: 4px;
        border-radius: 4px;
        transform: scale(0.75);
        transform-origin: left;
        font-weight: 500;
        text-transform: capitalize;
    }
    .commercial{
        background-color: #FFB387;
       
    }
    .residential{
        
        background-color: #2BC6FA;
    }
}

.land{
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
    .img-block{
        overflow: hidden;
        position: relative;
        height: 156px;
        border-radius: 16px;
        margin: 8px;
        .img{
            width: 100%;
        }
        .price{
            position: absolute;
            top: 8px;
            left: 8px;
            color: #357AEA;
            display: flex;
            .icon-small{
                width: 30px;
                height: 30px;
                z-index: 2;
            }
            .pill{
                background-color: #F2F2F2;
                border: 1px solid #CCCAC6;
                border-radius: 40px;
                font-weight: bold;
                font-size: 12px;
                line-height: 24px;
                height: 24px;
                padding: 0px 4px 0px 18px;
                margin-left: -16px;
                margin-top: 3px;
            
            }
        }
        .views{
            display: flex;    
            position: absolute;
            right: 8px;
            top: 8px;
            .icon-view{
                height: 24px;
            }
            .number{
                font-weight: 400;
                background-color: #F9F2EC;
                border-radius: 24px;
                font-size: 12px;
                line-height: 22px;
                padding: 1px 4px;
            }
        }
    }
    .info-block{
       
    
        padding: 0 8px;
          
        
        .title{
            font-weight: bold;
            font-size: 16px;
           
           
        }
        .address{
            font-weight: 400;
            font-size: 13px;
            line-height: 18px;
            
            margin-bottom: 4px;
        }
        .walk{
            font-weight: 300;
            font-size: 13px;
            line-height: 14px;
            color: #4F4F4F;
            transform: scale(0.75);
            transform-origin: left;
            margin-bottom: 4px;
        }

    }
    .btn-area{
        position: absolute;
        bottom: -24px;
        margin-left: 50%;
        left: -81px;
        
        
    }
    .tags{
        position: absolute;
        bottom: 8px;
        left: 8px;   
    }
    .tag{
        font-size: 13px;
        display: inline-flex;
        padding: 4px 10px;
        border-radius: 4px;
        transform: scale(0.75);
        transform-origin: left;
        font-weight: 500;
        text-transform: capitalize;

    }
    .commercial{
        background-color: #FFB387;
       
    }
    .sale{
        background-color: #D9D9D9;    margin-left: -8px;
    }
    .residential{
        
        background-color: #2BC6FA;
    }

}
.countdown{
    text-align: center;
    .img{
        width: 48px;
    }
    .t{
        font-size: 12px;
        line-height: 2em;
    }
    .second{
        .s{
            font-weight: 500;
            font-size: 18px;
        }
    }

}

.dailys.list{
    .item{
        background-color: #F2F2F2;
        border-radius: 8px;
        border: 1px solid #E0E0E0;
        box-shadow: 0px 2px 8px rgba(117, 117, 117, 0.25);
      
      
         &:hover{
        border: 1px solid #F2F2F2
    }   
    }

}
.scroll-block{
    height: 75vh;
    overflow-y: auto;
    &::-webkit-scrollbar { width: 0 !important };
}
.task{
    .item{
        flex-direction: column;
        .img{width: 100%;}
        .img-block{
            position: relative;
            .price{
                position: absolute;
                top: 8px;
                left: 8px;
                color: #357AEA;
                background-color: #F2F2F2;
                border: 1px solid #CCCAC6;
                border-radius: 40px;
                font-weight: 500;
                font-size: 14px;
                line-height: 18px;
                padding: 1px 4px;
                transform: scale(0.75);
                transform-origin: left;
            }
        }
        .info{
            display: flex;
            justify-content: space-between;
            width: 100%;
            align-items: center;
            margin-top: 5px;
            .title{
                display: flex;
                justify-content: center;
                align-items: center;
                font-size: 15px!important;
                
                .adm-tag{
                    transform: scale(0.75);
    transform-origin: left;
                    padding: 8px;
                    margin-left: 8px;
                    font-weight: 500;
                    font-size: 14px;
                    
                }
            }
        }

        .sub-n{
            display: flex;
            
            align-items: center;

            .subtract{    height: 50px;}
            .num{margin-top: -5px;
                font-weight: 400;
                font-size: 12px;
                line-height: 15px;}
            .add{    height: 50px;}
        }
        .s-item{
            width: 100%;
            .title{
                font-weight: 700;
                font-size: 12px;
                line-height: 15px;
                margin-top: 8px;
            }
            .address{
                font-weight: 300;
                font-size: 14px;
                line-height: 18px;
                transform: scale(0.75);
                transform-origin: left;
                margin-bottom: 4px;
            }
        }
        .btn-area{
            width: 100%;
        }
        .make-offer{
            background-color: #217895;font-size: 12px;
        } 
        .visit{
            font-size: 12px;
        }
    }
}
.nfts{
    .tools{
        .mini-icon{
            padding-right: 4px;
        }
        .v{font-weight: 700;
            font-size: 12px;
            line-height: 15px;}
        .n{font-weight: 300;
            font-size: 12px;
            line-height: 15px;            transform: scale(0.75);
            transform-origin: left;color: #000;}
        .left{display: flex;}
        .right{display: flex;}
    }
    .scroll-block{
        display: flex;  justify-content: space-between;  flex-direction: row;  flex-wrap: wrap;
    }
    .item{
        width: 50%;
        flex-direction: column;
        .img-block{
            .img{
                width:100%
            }
  
        }
        .s-item{
            display: flex;
            background-color: #333;
            width: 100%;
            align-items: center;
            justify-content: space-between;
            padding: 5px;
            border-bottom-left-radius: 8px;
            border-bottom-right-radius: 8px;
            .title{
                color: #fff;
                font-weight: 500;
                font-size: 12px;
                line-height: 26px;
                transform: scale(0.75);
                transform-origin: left;
            }
            .address{
                border-radius: 32px;
                background-color: #4DB6AC;
                font-weight: 600;
                font-size: 10px;
                line-height: 12px;
                color: #000;
                padding: 8px 12px;
                transform: scale(0.75);
                transform-origin: right;

            }
        }
    }
}
.cart{
    .item{
        flex-flow: column;
        .s-item{
            display: flex;
            width: 100%;
            padding-bottom: 10px;
            margin-bottom: 10px;
            align-items: center;
            .iconfont{
                color: #ed5565;
            }
            &:nth-child(1){
            border-bottom: 1px dotted #dedede;
            }
          
        }
        
        
    }
}
.icon-small{
    .iconfont{
        font-size: 25px;
    }
}
.popup{
    padding: 15px;
 
}
.iframe{
    height: 100vh;
    width: 100%;
}
.tools{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 15px;
    .left{
        width: 120px;
    }
    .right{
        width: 120px;
    }
    
}
.lands{
    width: 100%;
    position: absolute;
    left: 0;
    padding: 32px 20px;
    margin-top: -9px;
    background-color: #fff;
    border-top-left-radius: 30px;
    border-top-right-radius: 30px;
    &.residential{
        background-color: #F3EBFF;
    }
}





.list.properties{
    padding: 16px;
 
    text-align: center;

    background-image: url(../../assest/icon/popup-bg.png);
  
    background-size: 100%;
    background-repeat: no-repeat;
    .n-title{
        margin-top: 56px;
        font-size: 32px;
        text-align: left;
        color: #fff;
        font-weight: 700;padding-bottom: 24px;
        &.m{
            font-size: 24px;
            padding-bottom: 20px;
        }
    }
    .main-content{
        max-height: 440px;
        overflow-y: scroll;
        padding-bottom: 10px;
        &::-webkit-scrollbar{width: 0;}
    
        .pitem{
            display: flex;
            flex-direction: column;
            margin-bottom: 15px;
            .img-block{
                overflow: hidden;
                position: relative;
                
                border-radius: 16px;
              
                .img{
                    width: 100%;
                }
                .price{
                    position: absolute;
                    top: 8px;
                    left: 8px;
                    color: #357AEA;
                    display: flex;
                    .icon-small{
                        width: 30px;
                        height: 30px;
                        z-index: 2;
                    }
                    .pill{
                        background-color: #F2F2F2;
                        border: 1px solid #CCCAC6;
                        border-radius: 40px;
                        font-weight: bold;
                        font-size: 12px;
                        line-height: 24px;
                        height: 24px;
                        padding: 0px 4px 0px 18px;
                        margin-left: -16px;
                        margin-top: 3px;
                    
                    }
                }
                .views{
                    display: flex;    
                    position: absolute;
                    right: 8px;
                    top: 8px;
                    .icon-view{
                        height: 24px;
                    }
                    .number{
                        font-weight: 400;
                        background-color: #F9F2EC;
                        border-radius: 24px;
                        font-size: 12px;
                        line-height: 22px;
                        padding: 1px 4px;
                    }
                }
            }
            .info-block{
                text-align: left;
                .title{
                    font-weight: bold;
                    font-size: 16px;
                }
                .walk{
                    font-weight: 300;
                    font-size: 13px;
                    line-height: 14px;
                    color: #4F4F4F;
                    transform: scale(0.75);
                    transform-origin: left;
                    margin-bottom: 4px;
                }
        
            }
        
            .tags{
                position: absolute;
                bottom: 8px;
                left: 8px;   
            }
            .tag{
                font-size: 13px;
                display: inline-flex;
                padding: 4px 10px;
                border-radius: 4px;
                transform: scale(0.75);
                transform-origin: left;
                font-weight: 500;
                text-transform: capitalize;
        
            }
            .commercial{
                background-color: #FFB387;
               
            }
            .sale{
                background-color: #D9D9D9;    margin-left: -8px;
            }
            .residential{
                background-color: #2BC6FA;
            }
        
        }
        .item{
            background: #F9F2EC;
            box-shadow: 0px 4px 4px rgba(142, 142, 142, 0.25);
            border-radius: 26px;
            margin-left: 8px;
            margin-right: 8px;
            padding:14px 10px;
            margin-bottom: 12px;
            .left{    display: flex;
                line-height: 14px;
                padding: 3px 0;}
            .i-name{
                color: #222;
                font-size: 16px;
                font-weight: 700;
            }
            .in{
                display: flex;
                background-color: #3C86A9;
                border-radius: 24px;
                padding-right: 5px;
                .info-name{
                    width: 110px;
                    
                    color: #fff;
                    --color: #fff;
                    line-height: 2em;
                    font-weight: 500;
                    padding-left: 8px;
                    --text-align: center;
                    .adm-input-element{
                        font-size: 12px;
                    }
                }
                .icon-edit{
                    width: 16px;
                    height: 16px;
                    margin: 5px;
                }
            
            }
            &:nth-child(1){
                border-radius: 26px;
            }
            &.assets{
                flex-direction: column;
                .left{
                    align-self: flex-start;
                }
            }
        }
        .in-block-c{
            display: flex;
            flex-direction: column;
            width: 100%;
            .b-item{
                display: flex;
                flex-direction: row;    
                align-items: center;
                background-color: #EADBFF;
                border-radius: 48px;
                margin: 10px 0 10px 0;justify-content: space-between;
                .t{
                  
                    font-size: 15px;
                    font-weight: 700;
                    padding-left: 10px;line-height: 3.5em;
                }
                .v{
                    display: flex;
                    color: #0F1F68;
                    font-size: 16px;
                    font-weight: 700;
                    flex: 1;
                    padding-top: 8px;
                    justify-content: right;
                    padding-bottom: 8px;
                    padding-right: 8px;
                }
                .s-item{
                    padding-left: 4px;
                    .img-car{
                        border-radius: 16px;
                        width: 40px;
                    }
                }
       
            }
          
        }

    }
    .null-block{
        height: 100px;
       }
}


.cash-out{
    .cash-top{
        display: flex;
        align-items: center;
        position: relative;
        margin-top: 20px;
        justify-content: center;
        .icon{    
            position: absolute;
            left: 0;
        }
        .title{
            font-weight: 700;
            font-size: 24px;
        }
    }
    .info{
        text-align: center;
        .coin{
            display: flex;
            align-items: center;
            justify-content: center;
            margin-top: 45px;
            .ico{
                height: 16px;
            }
            .t{
                font-weight: 600;
                font-size: 16px;
                padding-left: 3px;
            }
        }
        .value{
            font-weight: 600;
            font-size: 46px;
            border-bottom: 1px solid #11216A;
            display: inline-flex;
            padding: 0 20px;
        }
        .ustd{
            font-weight: 600;
            font-size: 18px;
            padding-top: 12px;
        }
    }
    .btn-area{
        text-align: center;
        padding-top: 70px;
        .balance{
            font-weight: 400;
            font-size: 16px;
        }
        .btn-img{
            height: 72px;
        }
    }
}


.head-top{
    display: flex;
    justify-content: space-between;
    position: absolute;
    align-items: center;
    padding-right: 30px;
    z-index: 9;
    width: 100%;
  
    .center{transform:scale(0.75);
        display: flex;
        flex-direction: column;
        align-items: center;
        border-radius: 50px;
        justify-content: center;
        padding: 11px 13px;
        color: #fff;
       

        font-style: normal;
        font-weight: 500;
        font-size: 20px;
        line-height: 25px;
        background: #0F1F68;
        flex-direction: row;
        .currency{
            color: #2BC6FA;
        }
     
        .product{
            background: #2BC6FA;
            border-radius: 26px;
            font-weight: 800;
            font-size: 12px;
            line-height: 15px;
            display: flex;
            align-items: center;
            text-align: center;
            color: #000000;
            padding: 5px 9px;
            margin-left: 8px;
        }
    }

    .right-content{
        display: flex;
        flex-direction: row;
        .right{
            display: flex;
            flex-direction: row;
            .icon{
                width: 36px;
                height: 36px;
                z-index: 2;
            }
            .pill{
                font-style: normal;
                font-weight: 600;
                font-size: 16px;
                border-radius: 20px;
                margin: 3px 0 3px -20px;
                padding: 2px 10px 0 22px;
                background: #0F1F68aa;
                z-index: 1;
                color: #fff;
            }
        }
    }
}
.list.user{
    padding: 16px;
    text-align: center;

    background-image: url(../../assest/icon/popup-bg.png);
  
    background-size: 100%;
    background-repeat: no-repeat;
    .n-title{
        margin-top: 56px;
        font-size: 32px;
        text-align: left;
        color: #fff;
        font-weight: 700;padding-bottom: 24px;
    }
    .top-area{
        background-image: url(../../assest/icon/custom-top.png);
        background-repeat: no-repeat;
        background-position: top;
        height: 65px;
        background-size: 100%;
        position: absolute;
        width: 100%;
        left: 0;
        .t-icon{
            width: 96px;
            height: 96px;
            background-color: #F3EBFF;
            position: absolute;
            margin-top: -43px;
            margin-left: 50%;
            left: -43px;
            border: 5px solid #fff;
            border-radius: 90px;
            box-shadow: 0px 4px 4px rgb(142 142 142 / 25%);
            display: flex;
            align-items: center;
            justify-content: center;
            overflow: hidden;
            .t-img{
                width: 100%;
            }
        }
    }
    .container{
        background-color: #F3EBFF;
        border-top-left-radius: 30px;
        border-top-right-radius: 30px;
        max-height: 510px;
        overflow-y: scroll;
        border-top: 15px solid #fff;
        margin: 0 -16px 0 -16px;
        &::-webkit-scrollbar{width: 0;}
        .c-img{
            width: 100%;
        }
    }
    .main-content{
        background-color: #F3EBFF;
        border-left: 7px solid #fff;
        border-right: 7px solid #fff;
        border-bottom: 7px solid #fff;
        margin-bottom: 10px;
        padding-bottom: 27px;
        border-bottom-left-radius: 30px;
        border-bottom-right-radius: 30px;
        margin-top: 65px;
        max-height: 510px;
        overflow-y: scroll;
        &::-webkit-scrollbar{width: 0;}
        .c-title{
            font-weight: 600;
            font-size: 18px;
            line-height: 22.5px;
            text-align: center;
        }
        .c-des{
            font-size: 10.5px;
            text-align: center;
            padding-bottom: 15px;
        }
        .c-sub-des{
            font-size: 8.25px;
            text-align: center;
            padding-top: 15px;
            padding-bottom: 20px;
        }
        .item{
            background: #F9F2EC;
            box-shadow: 0px 4px 4px rgba(142, 142, 142, 0.25);
            border-radius: 26px;
            margin-left: 8px;
            margin-right: 8px;
            padding:14px 10px;
            margin-bottom: 12px;
            .left{    display: flex;
                line-height: 14px;
                padding: 3px 0;}
            .i-name{
                color: #222;
                font-size: 16px;
                font-weight: 700;padding-left: 8px;
            }
            .in{
                display: flex;
                background-color: #3C86A9;
                border-radius: 24px;
                padding-right: 5px;
                .info-name{
                    width: 110px;
                    font-size: 14px;
                    color: #fff;
                    --color: #fff;
                    line-height: 2em;
                    font-weight: 500;
                    padding-left: 8px;
                    --text-align: center;
                }
                .icon-edit{
                    width: 16px;
                    height: 16px;
                    margin: 5px;
                }
                &.wallet{
                    background-color: #FFC12C;
                    .info-name{
                        color: #000;
                    }

                }
            }
            &:nth-child(1){
                border-radius: 26px;
            }
            &.assets{
                flex-direction: column;
                .left{
                    align-self: flex-start;
                }
            }
        }
        .in-block-c{
            display: flex;
            flex-direction: column;
            width: 100%;
            .b-item{
                display: flex;
                flex-direction: row;    
                align-items: center;
                background-color: #EADBFF;
                border-radius: 48px;
                margin: 10px 0 10px 0;
                .t{
                    color: #676767;
                    font-size: 12px;
                    font-weight: 700;
                    padding-left: 10px;
                }
               .v{
                    display: flex;
                    color: #0F1F68;
                    font-size: 16px;
                    font-weight: 700;
                    flex: 1;
                    padding-top: 8px;
                    justify-content: right;
                    padding-bottom: 8px;
                    padding-right: 8px;
                } 
            }
            .s-item{
                padding-left: 4px;
                .img{
                    width: 100%;
                }
                .img-prop{

                }
                .img-nft{
                    border-radius: 16px;
                    width: 40px;
                }
            }
        }
        .in-block{
            display: flex;
            flex-direction: row;
            width: 100%;
            .b-item{
                display: flex;
                flex:1;
                flex-direction: column;
                background-color: #EADBFF;
                border-radius: 48px;
                padding: 8px 20px;
                margin: 10px 2px;
                .t{
                    color: #676767;
                    font-size: 12px;
                    font-weight: 700;
                }
                .v{
                    color: #0F1F68;
                    font-size: 16px;
                    font-weight: 700;
                }
            }

        }
        .icon-coin{
            width: 16px;
            height: 16px;
        }
    }

}


.list.custom{
    padding: 0;
    .top-area{
        background-image: url(../../assest/icon/custom-top.png);
        background-repeat: no-repeat;
        background-position: top;
        height: 65px;
        background-size: 100%;
        margin-top: -65px;
        .t-icon{
            width: 96px;
            height: 96px;
            background-color: #F3EBFF;
            position: absolute;
            margin-top: -43px;
            margin-left: 50%;
            left: -43px;
            border: 5px solid #fff;
            border-radius: 90px;
            box-shadow: 0px 4px 4px rgb(142 142 142 / 25%);
            display: flex;
            align-items: center;
            justify-content: center;
            .t-img{
                width: 100%;
            }
            .t-imgb{
                width: 44%;
            }
        }
        .t-text{
            display: flex;
            align-items: center;
            justify-content: center;
            font-weight: 600;
            font-size: 24px;
            position: absolute;
            margin-top: 30px;
            margin-left: 20%;
            width: 190px;
        }
    }
    .main-content{
        background-color: #F3EBFF;
        border-left: 7px solid #fff;
        border-right: 7px solid #fff;
        border-bottom: 7px solid #fff;
        margin-bottom: 10px;
        padding-bottom: 27px;
        border-bottom-left-radius: 30px;
        border-bottom-right-radius: 30px;
        .c-title{
            font-weight: 600;
            font-size: 18px;
            line-height: 22.5px;
            text-align: center;
        }
        .c-des{
            font-size: 10.5px;
            text-align: center;
            padding-bottom: 15px;
        }
        .c-sub-des{
            font-size: 8.25px;
            text-align: center;
            padding-top: 15px;
            padding-bottom: 20px;
        }
        .item{
            background: #F9F2EC;
            box-shadow: 0px 4px 4px rgba(142, 142, 142, 0.25);
            border-radius: 26px;
            margin-left: 8px;
            margin-right: 8px;
            padding: 8px 10px;
        }
        .icon-coin{
            width: 27px;
            height: 27px;
        }
    }
    .area-center{
        text-align: center;
       .btn-img{
        height: 54px;
       }
    }
    .area-center-large{
        text-align: center;
       .btn-img{
        height: 72px;
       }
    }
    .btn-area{
        display: flex;
        justify-content: space-between;
        .btn-img{
            height: 72px;
        }
    }
}
.cashout-preview {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
    .coin{
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 20px;
        .ico{
            height: 16px;
            margin-right: 5px;
        }
        .t{
            font-weight: 600;
            font-size: 16px;
            color: #4F4F4F;
        }
    }
    .value{
        font-weight: 600;
        font-size: 46px;
        border-bottom: 1px solid #4F4F4F;
        display: inline-flex;
        margin: 0 20px;
        justify-content: center;
    }
    .cp-item{
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: 10px 20px 0 20px;
    }
    .cp-sub-des{
        font-size: 9px;
        text-align: center;
        padding-top: 15px;
        padding-bottom: 20px;
    }
}

.congrats{
    padding: 15px 10px;
    .img{
        width: 100%;
    }
    .text{
        margin-top: 10px;
        text-align: center;
    }
    .btn-area{
        display: flex;
        justify-content: center;
        margin-top: 30px;
        margin-bottom: 30px;
        .submit{
            height: 72px;
        }    
    }
}

.restock{
    background-color: #EDE1FF;
    border-top-left-radius: 28px;
    border-top-right-radius: 28px;
    padding: 4px;
    .restock-c{
        background-color: #F9F2EC;
        border-top-left-radius: 28px;
        border-top-right-radius: 28px;
        padding: 14px;
    }
    .title{
        font-weight: 700;
        font-size: 16px;
    }
    .subtitle{
        font-size: 8px;
        padding-bottom: 12px;
    }
    .des{
        font-weight: 400;
        font-size: 12px;
    }
    .r-block{
        background-color: #EDE1FF;
        border-radius: 28px;
        padding: 14px;
        .icon-add{
            width: 57px;
            height: 57px;
        }
        .count{
            display: flex;
            align-items: center;
            justify-content: space-around;
            .num{font-weight: 600;
                font-size: 40px;}
        }
        .fee{
            font-weight: 700;
            font-size: 12px;
            display: flex;
            justify-content: space-between;
            border-top: 1px solid #4F4F4F;
            padding-top: 16px;
        }
        .item{
            font-size: 12px;
            display: flex;
            justify-content: space-between;
            padding-bottom: 8px;
        }
    }
    .btn-area{
        display: flex;
        justify-content: center;
        margin-top: 15px;
        .submit{
            height: 72px;
        }    
    }

}
.restock-deal{
    padding: 8px;
    position: relative;
    .restock-c{
        background-color: #F3EBFF;
        border-radius: 28px;
        padding: 14px;
    }
    .title{
        font-weight: 600;
        font-size: 24px;
        padding-top: 50px;
        text-align: center;
    }
    .des{
        text-align: center;
        font-weight: 400;
        font-size: 14px;
        line-height: 17px;
    }
    .t-block{
        height: 96px;
        width: 96px;
        filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
        position: absolute;
        top: -40px;
        left: 50%;
        margin-left: -48px;
    }
    .btn-area{
        display: flex;
        justify-content: center;
        margin-top: 30px;
        margin-bottom: 30px;
        .submit{
            height: 72px;
        }    
    }

}





.list{
    .item{
        display: flex;
        justify-content: space-between;
        align-items: center;
        background-color: #fff;
        
        padding: 10px;
        margin-bottom: 10px;
        &:last-child{
            margin-bottom: 0;
        }
        .info{
            flex: 1;
            
            .title{font-size: 12px;
                line-height: 15px;
                font-weight: 700;}
            .sub{   
                font-size: 13px;
                 transform: scale(0.75);
                transform-origin: left;}
            .sub-t{color: #747474;
                font-size: 12px;    transform: scale(0.75);
                transform-origin: left;}
        }
        .gradient-red{
            color: #fff;
        }
        .s-right{
            padding-left: 15px;
        }
    }
}
.close{
    position: absolute;
    top: 15px;
    left: 15px;
    width: 40px;
}

.adm-button.adm-button-primary{
    background-color: #0F1F68;
    box-shadow: 0px 4px 16px rgba(117, 117, 117, 0.25);
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    padding: 5px 16.5px;
}
.list .no-tools.adm-divider-horizontal{
    margin-top:5px;
    margin-bottom: 18px;
}
.adm-dialog-footer .adm-dialog-action-row > .adm-dialog-button{
    background-color: transparent;
    box-shadow: none;
}