@font-face {
    font-family: "Outfit"; /* Project id 5825 */
    src: url('Outfit-Black.ttf?t=1657801107318') format('truetype'),
         url('Outfit-Bold.ttf') format('truetype'),
         url('Outfit-ExtraBold.ttf') format('truetype'),
         url('Outfit-ExtraLight.ttf') format('truetype'),
         url('Outfit-Light.ttf') format('truetype'),
         url('Outfit-Medium.ttf') format('truetype'),
         url('Outfit-Regular.ttf') format('truetype'),
         url('Outfit-SemiBold.ttf') format('truetype'),
         url('Outfit-Thin.ttf') format('truetype'),
  }