@font-face {
  font-family: "iconfont"; /* Project id 5825 */
  src: url('iconfont.woff2?t=1657801107318') format('woff2'),
       url('iconfont.woff?t=1657801107318') format('woff'),
       url('iconfont.ttf?t=1657801107318') format('truetype');
}

.iconfont {
  font-family: "iconfont" !important;
  font-size: 16px;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-fanhui:before {
  content: "\e6ff";
}

.icon-home:before {
  content: "\e600";
}

.icon-close:before {
  content: "\e6a7";
}

.icon-location:before {
  content: "\e635";
}

.icon-3d:before {
  content: "\e601";
}

.icon-mail:before {
  content: "\e7bd";
}

.icon-cash:before {
  content: "\e6b5";
}

.icon-setting:before {
  content: "\e78e";
}

.icon-user:before {
  content: "\e7ae";
}

.icon-fangzidichan:before {
  content: "\e627";
}

.icon-searchcart:before {
  content: "\e763";
}

