body {
  margin: 0;
  font-family:'Outfit' !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.icon{
  width: 48px;
  height: 48px;
}
.icon-small{
  width: 40px;
  height: 40px;

}
.icon-buy{
  height: 72px;
}
.icon-go{   height: 36px;}
.icon-right{
  width: 10px;
}
.gradient-orange {
  border: none;
  background-image: linear-gradient(to bottom,#fc6e51,#e9573f);
}
.gradient-green {
  border: none;
  background-image: linear-gradient(to bottom,#a0d468,#8cc152);
}
.gradient-blue{
  background-image: linear-gradient(to bottom,#5d9cec,#4a89dc);
}
.gradient-red {
  background-image: linear-gradient(to bottom,#ed5565,#d84558);
}
.bg-white {
  background-color: #fff;
}


.color-green-dark {
  color: #8cc152;
}
.color-red-dark {
  color: #d84558;
}
.color-blue-dark {
  color: #4a89dc;
}
.color-brown-dark {
  color: #aa8e69;
}